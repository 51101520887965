import { NgModule } from '@angular/core';

import { DATEPICKER_DEFAULT_CONFIG, DROPDOWN_DEFAULT_CONFIG, dropdownDefaultConfig } from '@1stdigital/ng-sdk/controls';
import { FORMATTER_DEFAULT_CONFIG } from '@1stdigital/ng-sdk/core';
import { DATA_TABLE_DEFAULT_CONFIG } from '@1stdigital/ng-sdk/data-table';
import { CONFIRM_MODAL_COMPONENT } from '@1stdigital/ng-sdk/dialog';
import { VALIDATION_SCHEMA_ENDPOINT } from '@1stdigital/ng-sdk/form';
import { FDT_FORM_FIELD_DEFAULT_OPTIONS } from '@1stdigital/ng-sdk/form-field';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';

export const dataTableDefaultConfig = {
  paginationType: 'default',
  sortAscIcon: 'arrowUpLine',
  sortDescIcon: 'arrowDownLine',
  previousPageIcon: 'arrowLeftSLine',
  nextPageIcon: 'arrowRightSLine',
  lastPageIcon: 'skipRightLine',
  firstPageIcon: 'skipLeftLine',
  rowActionsIcon: 'more2Line',
  pageSize: 20,
  storeStateInUrl: true,
  skeletonConfig: {
    circle: {
      width: '32px',
      height: '32px',
    },
    line: {
      width: '140px',
      height: '16px',
    },
    rows: 3,
  },
};

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    {
      provide: DATA_TABLE_DEFAULT_CONFIG,
      useValue: dataTableDefaultConfig,
    },
    { provide: FDT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { size: 'md' } },
    {
      provide: DROPDOWN_DEFAULT_CONFIG,
      useValue: {
        ghostTypeOpenIcon: 'arrowDownSFill',
        ghostTypeCloseIcon: 'arrowUpSFill',
        buttonTypeOpenIcon: 'arrowDownSLine',
        buttonTypeCloseIcon: 'arrowUpSLine',
        defaultType: 'ghost',
        showSearchIfMoreThan: 5,
        placeholderText: 'Select',
        useNewCheckbox: true,
        searchFieldConfig: {
          ...dropdownDefaultConfig.searchFieldConfig,
          noResultText: '0 results',
        },
      },
    },
    {
      provide: DATEPICKER_DEFAULT_CONFIG,
      useValue: {
        toggleIcon: 'arrowDownSLine',
      },
    },
    {
      provide: VALIDATION_SCHEMA_ENDPOINT,
      useValue: 'validation/schema',
    },
    {
      provide: CONFIRM_MODAL_COMPONENT,
      useValue: ConfirmationModalComponent,
    },
    {
      provide: FORMATTER_DEFAULT_CONFIG,
      useValue: {
        symbolPosition: 'back',
      },
    },
  ],
})
export class CoreModule {}
