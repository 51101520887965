import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { map, of } from 'rxjs';

import { EnvironmentLoaderService } from '../services';
import { UserInfoStore } from '../services/user-info.store';

export const cardGuard: CanActivateFn = () => {
  const router = inject(Router);
  const envService = inject(EnvironmentLoaderService);
  const userInfoStore = inject(UserInfoStore);

  if (envService.environment['enableCard']) {
    if (envService.environment['cardApplication']) {
      return of(true);
    }

    return userInfoStore
      .getUserInfo()
      .pipe(map((userInfo) => userInfo!.isCardHolder || userInfo!.isCardAdmin || router.parseUrl('404')));
  }

  return router.parseUrl('404');
};
