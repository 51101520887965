import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { ClientService } from '@app/core/services';
import { map } from 'rxjs';

export const readonlyPermissionGuard: CanActivateFn = () => {
  const clientService = inject(ClientService);
  const router = inject(Router);

  return clientService.activeClient$.pipe(
    map((client) => (client?.isOnlineAccessReadOnly ? router.parseUrl('404') : true))
  );
};
