<div
  class="cp-info-block"
  [class.cp-info-block--icon-centered]="iconPosition === 'center'"
  [class.cp-info-block--tertiary]="color === 'tertiary'"
>
  @if (showIcon) {
    <fdt-icon size="24" name="informationLine"></fdt-icon>
  }
  <ng-content></ng-content>
</div>
