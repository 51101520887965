import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';

import { AnalyticsService } from '@app/core/services';
import { TrackEventDirective } from '@app/shared/directives/track-event.directive';
import { TourHighlightDirective } from '@app/tour/tour-highlight.directive';

import { InboxMessageLayoutComponent } from '../inbox-message-layout/inbox-message-layout.component';

@Component({
  selector: 'app-inbox-widget',
  standalone: true,
  imports: [MatMenuModule, InboxMessageLayoutComponent, TourHighlightDirective, TrackEventDirective],
  templateUrl: './inbox-widget.component.html',
  styleUrl: './inbox-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxWidgetComponent {
  constructor(private analyticsService: AnalyticsService) {}

  @Input() hasNewMessage = false;

  closeWidget(trigger: MatMenuTrigger): void {
    trigger.closeMenu();
  }
}
