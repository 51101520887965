<div class="ticket-form">
  <div class="ticket-form__title" [class.ticket-form__title--small]="showCloseButton">
    Create ticket
    @if (showCloseButton) {
      <button class="ticket-form__close-btn" fdt-fab-button color="tertiary" size="large" (click)="onModalClose()">
        <fdt-icon name="closeLine"></fdt-icon>
      </button>
    }
  </div>
  <div class="ticket-form__content" [formGroup]="ticketForm">
    <fdt-form-field>
      <fdt-label>Subject</fdt-label>
      <fdt-input [formControl]="ticketForm.controls.subject"></fdt-input>
      <fdt-hint>Short summary of your inquiry</fdt-hint>
    </fdt-form-field>
    <fdt-form-field>
      <fdt-label>Message</fdt-label>
      <fdt-textarea [formControl]="ticketForm.controls.message"></fdt-textarea>
      <fdt-hint>Please describe your issue in as much detail as possible</fdt-hint>
    </fdt-form-field>
    <app-info-block [showIcon]="false">
      <div>
        You can also visit our
        <a [href]="helpDeskUrl" target="_blank" class="ticket-form__link" appTrackEvent="Help centre url link clicked">
          Help Centre
        </a>
        for guides & FAQs related to your issue.
      </div>
    </app-info-block>
    <button
      class="ticket-form__submit-btn"
      [disabled]="isButtonDisabled"
      [loading]="isBusy"
      fdt-button
      (click)="onCreateTicketClick()"
      appTrackEvent="Submit ticket clicked"
    >
      Submit
    </button>
  </div>
</div>
