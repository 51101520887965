import { Injectable } from '@angular/core';

import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';

import { UserInfoResponse } from '../models/interfaces/user-info.interface';
import { UserInfoApiService } from './user-info-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoStore {
  private readonly _userInfo$ = new BehaviorSubject<UserInfoResponse | null>(null);
  private readonly _isBusy$ = new BehaviorSubject(false);

  userInfo$ = this._userInfo$.asObservable();
  isBusy$ = this._isBusy$.asObservable();

  constructor(private userInfoApiService: UserInfoApiService) {}

  getUserInfo(): Observable<UserInfoResponse> {
    this._isBusy$.next(true);
    return this.userInfoApiService.getUserInfo().pipe(
      tap((userInfo) => this._userInfo$.next(userInfo)),
      finalize(() => this._isBusy$.next(false))
    );
  }
}
