@switch (eventId) {
  @case ('ams.card.application.created') {
    Your First Digital Card application has been successfully created.
  }
  @case ('ams.card.application.rejected') {
    Your application has been rejected. Please contact our Customer Success Team for more info.
  }
  @case ('ams.card.application.approved') {
    Congratulations! Your card application was successful. Start spending your assets at 100+ merchants globally.
  }
  @case ('ams.card.activated') {
    Your First Digital Card has been successfully activated. You can now start spending it.
  }
  @case ('ams.card.blocked') {
    Your card has been blocked. Please contact your dedicated Account Manager for info.
  }
  @case ('ams.card.frozen') {
    Your card has been frozen. Please contact your dedicated Account Manager for more info.
  }
  @case ('ams.card.unfrozen') {
    Your First Digital Card has been unfrozen and is available for use.
  }
  @case ('ams.card.instruction.created') {
    Your top-up request of {{ requestData.amount }} has been initiated successfully.
  }
  @case ('ams.card.balance.updated') {
    Your First Digital Card balance has been updated. New balance: {{ requestData.amount }}.
  }
  @case ('ams.card.limit.reaching') {
    Further usage will result in temporary restrictions until the daily spending limit resets.
  }
  @case ('ams.card.limit.reached') {
    You have reached your daily limit of spending. Transactions will be temporarily restricted until the limit resets.
  }
  @case ('ams.card.transactionsync.completed') {
    You spent {{ requestData.amount }} at {{ requestData.merchantName }} on
    {{ requestData.transactionDate! | fdtDateTime: 'medium' }}.
  }
  @case ('ams.card.monthlystatement.generated') {
    Please find enclosed your latest First Digital Corporate Card e-statement in Card page.
  }
}
