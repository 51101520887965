import { inject, Injectable } from '@angular/core';

import { ClientService } from '@app/core/services/client.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  private readonly userInfoStore = inject(ClientService);
  hasPermission(): boolean {
    const activeClient = this.userInfoStore.activeClient$.value;

    if (!activeClient) {
      return true;
    }

    return !activeClient.isOnlineAccessReadOnly;
  }
}
