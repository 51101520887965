import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IconRegistry, IconRegistryToken } from '@1stdigital/ng-sdk/icon';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthError, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';

import { LogoutService } from './core/services/logout.service';
import { ICON_REGISTRY } from './icons';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
  private ngUnsub = new Subject<void>();
  title = 'FDT-ClientPortal-NextGen';
  isIframe: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(IconRegistryToken) iconRegistry: IconRegistry,
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private logoutService: LogoutService,
    private analyticsService: AnalyticsService
  ) {
    iconRegistry.registerIcons(ICON_REGISTRY);
  }

  ngOnInit(): void {
    this.isIframe =
      this.document.defaultView !== this.document.defaultView?.parent && !this.document.defaultView?.opener;

    // This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        ),
        takeUntil(this.ngUnsub)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          // reload/redirect to dashboard when user logs in in another tab or window
          if (this.document.defaultView) {
            this.document.defaultView.location.pathname = '/';
          }
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.ngUnsub)
      )
      .subscribe(() => {
        this.setActiveAccount();

        const activeAccount = this.authService.instance.getActiveAccount();

        if (activeAccount) {
          this.analyticsService.identify(activeAccount.localAccountId);
          this.analyticsService.track('Login success');
        }

        if (this.document.defaultView?.location.pathname === '/redirecting') {
          this.router.navigate(['/']);
        }
      });

    // for azure b2c unhandled errors
    // mainly for (AADB2C90091: The user has cancelled entering self-asserted information.) error
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.ngUnsub)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // after user clicks 'Cancel' button from Change password page
          // user should return back to the profile page, the page they were at before getting into the b2c change password page
          if (this.document.defaultView?.location.pathname === '/my-profile') {
            this.document.defaultView.location.pathname = '/my-profile';
          } else {
            this.authService.loginRedirect();
          }
        }
      });

    this.logoutService.logoutChannel.onmessage = (): void => this.logoutService.logout();
  }

  setActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this.analyticsService.reset();
  }
}
