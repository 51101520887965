import { ErrorHandler, inject, Injectable } from '@angular/core';

import { EnvironmentLoaderService, LoggingService } from '@app/core/services';
import { RaygunV2 } from 'raygun4js';

/**
 * Raygun error handler
 * Sends errors to Raygun
 *
 * This should be extended to include application version!
 *
 * @see https://raygun.com/
 */
@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  private readonly envService = inject(EnvironmentLoaderService);
  private readonly loggingService = inject(LoggingService);

  private rg4jsPromise: Promise<RaygunV2> | null = null;
  private isEnabled: null | boolean = null;

  handleError(e: Error): void {
    this.beforeHandleError();

    if (!this.isEnabled) {
      // so error is not `eaten` by RaygunErrorHandler
      this.loggingService.error(e);
      return;
    }

    if (!this.rg4jsPromise) {
      this.rg4jsPromise = import('raygun4js').then((m) => m.default);
      this.rg4jsPromise.then((rg4js) => {
        rg4js('apiKey', this.envService.environment['raygunApiKey'] as string);
        rg4js('enableCrashReporting', true);
      });
    } else {
      this.rg4jsPromise.then((rg4js) => rg4js('send', { error: e }));
    }
  }

  private beforeHandleError(): void {
    if (this.isEnabled === null) {
      // raygun not initialized yet
      this.isEnabled = !!this.envService.environment['raygunApiKey'] && this.envService.environmentName !== 'local';
    }
  }
}
