import { AssetClasses } from '@1stdigital/ng-sdk/core';
import { StatusDescriptor } from '@1stdigital/ng-sdk/shared';

export interface AssetAccountDto {
  clientName: string;
  assetAccountNumber: string;
  assetClass?: AssetClasses;
  assetAccountAlias?: string | null;
  assetAccountId?: number;
}

export interface CryptoAccountDetailsDto extends AssetAccountDto {
  assetName: string;
  assetSymbol: string;
  walletAddress: string;
  network: string;
  clientName: string;
  assetAccountNumber: string;
  assetClass?: AssetClasses;
}

export type AssetAccountStatus = 'active' | 'suspended';

export const assetAccountStatusConfig: StatusDescriptor<AssetAccountStatus>[] = [
  {
    label: 'Suspended',
    value: 'suspended',
    icon: 'spam3Fill',
    color: 'accent',
  },
];
